<template>
	<table-page
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="advertisements2532"
		tooltipMsg="manualfordemand"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OdemandDatetime', ascending: 0 }"
		:modelId="modelId"
		addNewRecordMsg
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="ObjectDemandTable"
	>
		<template #OdemandDatetime="list">{{ list.row.OdemandDatetime | dateTime }}</template>

		<template #OdemandIsActive="list">
			<div class="d-flex flex-column align-items-start justify-content-center">
				<a href="#" @click.prevent="openActiveModal(list.row.DomainUrl, list.row.Code, list.row.OdemandIsConfirmed)">
					{{ list.row.OdemandIsConfirmed ? $t('deactivate') : $t('activate') }}
				</a>

				<span>
					{{ formatConfiremdDate(list.row.OdemandIsConfirmed, list.row.DemandStatusChangeDatetime) }}
				</span>
			</div>
		</template>

		<template #ObjectsCount="list">{{ list.row.ObjectsCount === null ? '-' : list.row.ObjectsCount }}</template>

		<template #rowActions="list">
			<megau-button
				classprop="btn btn-success blue mr-1 ml-1"
				icon="eye"
				:tooltip="$t('preview2534')"
				@handleClick="routeToPreview(list.row.DomainUrl, list.row.Code)"
			></megau-button>

			<megau-button
				classprop="btn btn-primary blue mr-1 ml-1"
				icon="edit"
				:title="$t('edit')"
				titlePosition="left"
				@handleClick="redirectToDetail(list.row[modelId])"
			></megau-button>

			<megau-button
				classprop="btn btn-customdanger ml-1"
				icon="trash-alt"
				:tooltip="$t('delete')"
				@handleClick="openDeleteModal(list.row[modelId])"
			></megau-button>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import serviceCommon from '@/services/service/common.service';

import service from './object-demand.service';
import { tableColumns } from './object-demand.table-data';

export default {
	name: 'ObjectDemandTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectDemandListItem',
			columns: tableColumns,
			modelId: 'OdemandId',
		};
	},

	methods: {
		redirectToDetail(OdemandId = 0) {
			this.$router.push({
				name: links.objectDemandDetailName,
				params: { OdemandId },
			});
		},

		refresh() {
			this.tableKey += 1;
		},

		openActiveModal(url, code, isActive) {
			service.openChangeActiveModal(this.$modal, this.changeActive, url, code, isActive);
		},

		changeActive(url, code) {
			console.log('this.$refs.ObjectDemandTable', this.$refs.ObjectDemandTable);
			service.changeActiveStatus(url, this.$refs.ObjectDemandTable);
		},

		formatConfiremdDate(isChecked, dateTimeChangeStatus) {
			return service.formatConfiremdDate(isChecked, dateTimeChangeStatus);
		},

		routeToPreview(domainUrl, code) {
			window.open(domainUrl + 'Home/DemandPreview?code=' + code);
		},

		openDeleteModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteThisItem);
		},

		deleteThisItem(id) {
			serviceCommon.deleteItem(id, this.tableGetData, this.controllerName);
		},
	},
};
</script>

<style lang="scss">
.ObjectDemandTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(8) {
		max-width: 100px;
	}
}
</style>
