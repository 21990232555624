var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    ref: _vm.$options.name,
    staticClass: "ObjectDemandTable",
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "advertisements2532",
      tooltipMsg: "manualfordemand",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "OdemandDatetime", ascending: 0 },
      modelId: _vm.modelId,
      addNewRecordMsg: "",
    },
    on: {
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "OdemandDatetime",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm._f("dateTime")(list.row.OdemandDatetime)))]
        },
      },
      {
        key: "OdemandIsActive",
        fn: function (list) {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column align-items-start justify-content-center",
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openActiveModal(
                          list.row.DomainUrl,
                          list.row.Code,
                          list.row.OdemandIsConfirmed
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          list.row.OdemandIsConfirmed
                            ? _vm.$t("deactivate")
                            : _vm.$t("activate")
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        _vm.formatConfiremdDate(
                          list.row.OdemandIsConfirmed,
                          list.row.DemandStatusChangeDatetime
                        )
                      ) +
                      "\n\t\t\t"
                  ),
                ]),
              ]
            ),
          ]
        },
      },
      {
        key: "ObjectsCount",
        fn: function (list) {
          return [
            _vm._v(
              _vm._s(
                list.row.ObjectsCount === null ? "-" : list.row.ObjectsCount
              )
            ),
          ]
        },
      },
      {
        key: "rowActions",
        fn: function (list) {
          return [
            _c("megau-button", {
              attrs: {
                classprop: "btn btn-success blue mr-1 ml-1",
                icon: "eye",
                tooltip: _vm.$t("preview2534"),
              },
              on: {
                handleClick: function ($event) {
                  return _vm.routeToPreview(list.row.DomainUrl, list.row.Code)
                },
              },
            }),
            _c("megau-button", {
              attrs: {
                classprop: "btn btn-primary blue mr-1 ml-1",
                icon: "edit",
                title: _vm.$t("edit"),
                titlePosition: "left",
              },
              on: {
                handleClick: function ($event) {
                  return _vm.redirectToDetail(list.row[_vm.modelId])
                },
              },
            }),
            _c("megau-button", {
              attrs: {
                classprop: "btn btn-customdanger ml-1",
                icon: "trash-alt",
                tooltip: _vm.$t("delete"),
              },
              on: {
                handleClick: function ($event) {
                  return _vm.openDeleteModal(list.row[_vm.modelId])
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }