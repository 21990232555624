import i18n from '@/services/lang';

export const tableColumns = [
			{
				model: 'OdemandDatetime',
				i18n: 'created',
				sortable: true,
				filter: 'daterange',
			},
			{
				model: 'LocationName',
				i18n: 'locality',
				sortable: true,
				filter: 'text',
			},
			{
				model: 'ObjectsCount',
				i18n: 'incount',
				sortable: true,
				filter: 'numberrange',
			},
			{
				model: 'OdemandName',
				i18n: 'name',
				sortable: true,
				filter: 'text',
			},
			{
				model: 'OdemandEmail',
				i18n: 'e-mail',
				sortable: true,
				filter: 'text',
			},
			{
				colType: 'country',
				model: 'CountryId',
				i18n: 'country',
				sortable: true,
			},
			{
				model: 'OdemandIsActive',
				i18n: 'active',
				sortable: true,
				filter: 'boolean',
			},
		];
